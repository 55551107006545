import * as THREE from "three";
import { useEffect, useRef, Suspense } from "react";
import { CameraControls, Environment } from "@react-three/drei";
import React from "react";
import { useCustomEventListener } from "react-custom-events";
import CameraControls2 from "camera-controls";
import { RoomViewEnum } from "../enums/RoomViewEnum";
import Lights from "./Lights";
// import { BasicModel } from "./component3d/BasicModel";
import { VariantModel } from "./component3d/VariantModel";
import { BaseModel } from "./component3d/ModelsTemp";
import Store from "../store/Store";
import { useSnapshot } from "valtio";

export const Experience = ({ checkedOptions }) => {
  const controls = useRef();
  const snap = useSnapshot(Store);
  useEffect(() => {
    let cameraControls = controls.current;
    window.cameraControls = cameraControls;
    configureControls(cameraControls);
  }, []);

  let movementSpeed = 0.02;
  useCustomEventListener("moveLeft", (e) => {
    controls.current.truck(-movementSpeed * (e || 1), 0, true);
  });

  useCustomEventListener("moveRight", (e) => {
    controls.current.truck(movementSpeed * (e || 1), 0, true);
  });

  useCustomEventListener("moveIn", (e) => {
    controls.current.forward(movementSpeed * (e || 1), true);
  });

  useCustomEventListener("moveOut", (e) => {
    controls.current.forward(-movementSpeed * (e || 1), true);
  });
  useCustomEventListener("setView", (e) => {
    let view;
    switch (e) {
      case RoomViewEnum.FIRST:
        view = cameraView.defaultView;
        break;
      case RoomViewEnum.SECOND:
        view = cameraView.secondView;
        break;
      case RoomViewEnum.THIRD:
        view = cameraView.thirdView;
        break;
      default:
        view = cameraView.firstView;
        break;
    }
    let pos = view.position;
    let rot = view.rotation;
    controls.current.moveTo(pos[0], pos[1], pos[2], true);
    let cameraRotation = new THREE.Euler(rot[0], rot[1], rot[2], "XYZ");
    controls.current.rotateTo(cameraRotation.x, cameraRotation.y, true);
  });
  useEffect(() => {
    const { data } = snap;
    const position = data.default_camera_position;
    const rotation = data.default_camera_rotation;
    controls.current?.moveTo(position[0], position[1], position[2], true);
    let cameraRotation = new THREE.Euler(
      rotation[0],
      rotation[1],
      rotation[2],
      "XYZ"
    );
    controls.current?.rotateTo(cameraRotation.x, cameraRotation.y, true);
    Store.loadingState = false;
  }, []);

  return (
    <>
      <Environment preset="night" />
      <Lights />
      <CameraControls ref={controls} />
      <Suspense>
        <BaseModel />
      </Suspense>
      <VariantModel checkedOptions={checkedOptions} />
    </>
  );
};

const cameraView = {
  defaultView: {
    position: [1.05, 1.5, 0.77],
    rotation: [1.1, 1.35, 0],
  },
  firstView: {
    position: [1.05, 1.5, 0.77],
    rotation: [1.1, 1.35, 0],
  },
  secondView: {
    position: [1.23, 1.7, 1],
    rotation: [1, 1.35, 0],
  },
  thirdView: {
    position: [0.67, 1.6, -0.6],
    rotation: [2.6, 1.35, 0],
  },
};

function configureControls(inControls) {
  // Set up first person controls
  inControls.minPolarAngle = 0;
  inControls.minDistance = inControls.maxDistance = 1;
  inControls.azimuthRotateSpeed = -0.3;
  inControls.polarRotateSpeed = -0.3;
  inControls.truckSpeed = 10;
  inControls.minZoom = 1;
  inControls.maxZoom = 2.5;
  inControls.mouseButtons.wheel = CameraControls2.ACTION.ZOOM;
  inControls.mouseButtons.middle = CameraControls2.ACTION.NONE;
  inControls.mouseButtons.right = CameraControls2.ACTION.NONE;

  let shrink = 0.2;
  let box3 = new THREE.Box3();

  box3.setFromCenterAndSize(
    new THREE.Vector3(0.047, 1.2, 0.2),
    new THREE.Vector3(2.6, 1.2, 1.7)
  );
  box3.min.add(new THREE.Vector3(shrink, shrink, shrink));
  box3.max.add(new THREE.Vector3(-shrink, -shrink, -shrink));

  // Set boundary
  inControls.setBoundary(box3);
}
