// import { useSnapshot } from "valtio";
// import Store from "../../store/Store";
// import { GlbModel } from "./assets/GlbModel";

// export const VariantModel = ({  }) => {

//     const snap = useSnapshot(Store);
//     const { variant, data } = snap;
//     const variantData = data.bathroom_type.find((item) => item.name === variant);

//     const variantModels = variantData?.models || [];
//     const variantModelURLs = variantModels.map((model) => snap.base_url + variantData.dir_name + "/" + model + ".glb");
//     return (
//         <>
//            <object3D>
//                 {variantModelURLs.map((url, index) => {
//                     let castShadow = false;
//                     let receiveShadow = false;
//                     return <GlbModel key={index} url={url} castShadow={castShadow} receiveShadow={receiveShadow} />
//                 })}
//             </object3D>
//         </>
//     );
// };

import { useSnapshot } from "valtio";
import Store from "../../store/Store";
import { Spa, Glamoure, Urban, Vintage, Contemporary } from "./ModelsTemp";
import { Suspense } from "react";

export const VariantModel = ({ checkedOptions }) => {
  const snap = useSnapshot(Store);
  const { variant } = snap;

  return (
    <>
      <Suspense>
        <Spa visible={variant === "SPA"} checkedOptions={checkedOptions} />
      </Suspense>
      {/* <Urban visible={variant === "SPA"} checkedOptions={checkedOptions} /> */}
      <Suspense>
        <Contemporary
          visible={variant === "Contemporary"}
          checkedOptions={checkedOptions}
        />
      </Suspense>
      <Suspense>
        <Glamoure
          visible={variant === "Glamoure"}
          checkedOptions={checkedOptions}
        />
      </Suspense>
      <Suspense>
        <Urban visible={variant === "Urban"} checkedOptions={checkedOptions} />
      </Suspense>
      <Suspense>
        <Vintage
          visible={variant === "Vintage"}
          checkedOptions={checkedOptions}
        />
      </Suspense>
    </>
  );
};
